import {
  Alert,
  Field,
  MultiCombobox,
  Search,
  Select,
  Text,
  TextArea,
  TextInput,
  type AlertContent,
  type FormControlOption,
} from "@kablamooss/geo-ds-core-components";
import compact from "lodash/compact";
import { useId } from "react";
import { Controller, useFormContext } from "react-hook-form";
import styled from "styled-components";
import { useGetBfmcs } from "../../../.rest-hooks/bfmcs";
import { ServiceProjectTypeAttribute } from "../../../.rest-hooks/types";
import {
  DESCRIPTION_MAX_LENGTH,
  NOTES_MAX_LENGTH,
} from "../../../config/constants";
import { useHasCreateProjectPrivilege } from "../../../hooks/useHasCreateProjectPrivilege";
import { ProjectCategory, type Project } from "../../../types";
import deserializeJsonApi from "../../../utils/deserializeJsonApi/deserializeJsonApi";

export const DESCRIPTION_ERROR_MESSAGE = `Description must be ${DESCRIPTION_MAX_LENGTH} characters or less`;

const statewideProjectTypeOptions: FormControlOption<ServiceProjectTypeAttribute>[] =
  [
    {
      label: "Max Fuel Loads",
      value: ServiceProjectTypeAttribute["statewide-maxfuels"],
    },
    {
      label: "Snapshot",
      value: ServiceProjectTypeAttribute["statewide-snapshot"],
    },
    {
      label: "AWP Forecast",
      value: ServiceProjectTypeAttribute["annual-works-plan-forecast"],
    },
    {
      label: "AWP Evaluation",
      value: ServiceProjectTypeAttribute["annual-works-plan-evaluation"],
    },
  ];

interface GetLocalProjectTypeOptionsProps {
  hasCreateProjectPermission?: Record<Project["type"], boolean>;
}

const getLocalProjectTypeOptions = ({
  hasCreateProjectPermission,
}: GetLocalProjectTypeOptionsProps): FormControlOption<ServiceProjectTypeAttribute>[] => {
  return compact([
    hasCreateProjectPermission?.["individual-treatment-comparison"] && {
      label: "Individual Treatment Comparison",
      value: ServiceProjectTypeAttribute["individual-treatment-comparison"],
    },
    hasCreateProjectPermission?.["individual-treatment-comparison-brigade"] && {
      label: "Brigade Treatment Comparison",
      value:
        ServiceProjectTypeAttribute["individual-treatment-comparison-brigade"],
    },
    hasCreateProjectPermission?.["modelled-impact-time"] && {
      label: "Modelled Impact Time",
      value: ServiceProjectTypeAttribute["modelled-impact-time"],
    },
  ]);
};

export const StyledCreateProjectForm = styled.div`
  display: grid;
  gap: 1rem;
  padding-top: 0.5rem;
`;

const StyledDescriptionField = styled.div`
  display: flex;
  gap: 0.25rem;
  align-items: center;
`;

const StyledDescriptionInput = styled(TextInput)`
  width: 100%;
`;

const StyledNumericCount = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 3rem;
`;

export interface CreateProjectFormValues {
  bfmcs: FormControlOption[];
  description: string;
  name: string;
  notes: string;
  type: FormControlOption<ServiceProjectTypeAttribute> | null;
}

export const getDefaultValues = (
  projectCategory: ProjectCategory,
): CreateProjectFormValues => {
  return {
    bfmcs: [],
    description: "",
    name: "",
    notes: "",
    type:
      projectCategory === ProjectCategory.BFRMP
        ? {
            label: "BFRMP",
            value: ServiceProjectTypeAttribute.bfrmp,
          }
        : null,
  };
};

interface CreateProjectFormProps {
  projectCategory: ProjectCategory;
  userError: AlertContent | null;
}

const CreateProjectForm = ({
  projectCategory,
  userError,
}: CreateProjectFormProps) => {
  const hasCreateProjectQuery = useHasCreateProjectPrivilege();
  const canOnlyCreateITCBrigadeProject =
    hasCreateProjectQuery.data &&
    Object.entries(hasCreateProjectQuery.data).every(
      ([key, value]) =>
        (key ===
          ServiceProjectTypeAttribute[
            "individual-treatment-comparison-brigade"
          ] &&
          value === true) ||
        (key !==
          ServiceProjectTypeAttribute[
            "individual-treatment-comparison-brigade"
          ] &&
          value === false),
    );
  const {
    control,
    formState: { isSubmitting },
    watch,
  } = useFormContext<CreateProjectFormValues>();

  const projectType = watch("type");
  const descriptionLength = watch("description").length;

  const {
    data,
    isError: isGetBfmcsError,
    isPending: isGetBfmcsLoading,
  } = useGetBfmcs(
    { perPage: 100 },
    {
      query: {
        select: (response) => deserializeJsonApi(response.data),
      },
    },
  );

  const bfmcs = data?.data || [];
  const bfmcsOptions: FormControlOption[] = bfmcs.map((bfmc) => {
    return {
      value: bfmc.id,
      label: bfmc.name,
    };
  });

  const accessibilityId = useId();

  return (
    <StyledCreateProjectForm>
      {projectCategory === ProjectCategory.STATEWIDE && (
        <Controller
          control={control}
          name="type"
          render={({ field, fieldState }) => (
            <Select
              {...field}
              error={fieldState.error}
              id={`${accessibilityId}-${field.name}`}
              label="Type of project"
              options={statewideProjectTypeOptions}
              placeholder="Select"
              validationStatus={fieldState.error && "error"}
            />
          )}
          rules={{
            validate: (value) => !!value || "Type of project is required",
          }}
        />
      )}
      {projectCategory === ProjectCategory.BFRMP && (
        <Controller
          control={control}
          name="bfmcs"
          render={({ field, fieldState: { error } }) => {
            return (
              <MultiCombobox
                {...field}
                error={
                  isGetBfmcsError ? "Unable to load BFMCs. Try again." : error
                }
                icon={Search}
                isLoading={isGetBfmcsLoading}
                label="BFMC(s)"
                options={bfmcsOptions}
                placeholder="Search"
                data-testid="bfmc"
              />
            );
          }}
          rules={{
            validate: (value) =>
              !!value.length || "At least one BFMC is required",
          }}
        />
      )}
      {canOnlyCreateITCBrigadeProject ||
        (projectType &&
          projectType.value ===
            ServiceProjectTypeAttribute[
              "individual-treatment-comparison-brigade"
            ] &&
          (userError ? (
            <Alert title={userError.title} variant="error">
              {userError.message}
            </Alert>
          ) : (
            <Alert title="Getting started with your project" variant="info">
              You can run two district-approved modelling projects each
              financial year to explore the effects of different treatment
              options. For information on running Treatment Comparison projects,
              visit the RMP Help Centre.
            </Alert>
          )))}
      {projectCategory === ProjectCategory.LOCAL && (
        <Controller
          control={control}
          name="type"
          render={({ field, fieldState }) => (
            <Select
              {...field}
              error={fieldState.error}
              id={`${accessibilityId}-${field.name}`}
              label="Type of Local project"
              options={getLocalProjectTypeOptions({
                hasCreateProjectPermission: hasCreateProjectQuery.data,
              })}
              placeholder="Type of Local project"
              validationStatus={fieldState.error && "error"}
            />
          )}
          rules={{
            validate: (value) => !!value || "Type of project is required",
          }}
        />
      )}
      {projectCategory === ProjectCategory.LOCAL && projectType && (
        <Controller
          control={control}
          name="description"
          render={({ field, fieldState: { error } }) => (
            <Field
              error={error}
              htmlFor={`${accessibilityId}-${field.name}`}
              label="Description"
              hint="Include the location and intent of your project."
            >
              <StyledDescriptionField>
                <StyledDescriptionInput
                  disabled={isSubmitting}
                  id={`${accessibilityId}-${field.name}`}
                  placeholder="Enter project description"
                  validationStatus={error && "error"}
                  {...field}
                />

                <StyledNumericCount>
                  <Text
                    size="bodyDefault"
                    variant={
                      descriptionLength > DESCRIPTION_MAX_LENGTH
                        ? "error"
                        : "text"
                    }
                  >
                    {descriptionLength}/{DESCRIPTION_MAX_LENGTH}
                  </Text>
                </StyledNumericCount>
              </StyledDescriptionField>
            </Field>
          )}
          rules={{
            required: "Description is required",
            maxLength: {
              value: DESCRIPTION_MAX_LENGTH,
              message: DESCRIPTION_ERROR_MESSAGE,
            },
          }}
        />
      )}
      {(projectCategory !== ProjectCategory.LOCAL ||
        (projectCategory === ProjectCategory.LOCAL && projectType)) && (
        <Controller
          control={control}
          name="notes"
          render={({ field, fieldState: { error } }) => (
            <Field
              error={error}
              hint="Add any additional details or relevant notes about your project."
              htmlFor={`${accessibilityId}-${field.name}`}
              label="Notes"
            >
              <TextArea
                data-testid="project-notes"
                disabled={isSubmitting}
                id={`${accessibilityId}-${field.name}`}
                maxLength={NOTES_MAX_LENGTH}
                rows={5}
                {...field}
              />
            </Field>
          )}
        />
      )}
    </StyledCreateProjectForm>
  );
};

export default CreateProjectForm;
