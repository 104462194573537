import type { MergedUnion } from "@kablamo/kerosene";
import {
  CheckboxGroupDropdownChip,
  ChipGroup,
} from "@kablamooss/geo-ds-core-components";
import type {
  AppFilterOption,
  ServiceProjectTypeAttribute,
} from "../../../.rest-hooks/types";
import {
  statusItems,
  bfrmpScenarioItems,
  LocalProjectTypeItems,
} from "../../../config/projects";
import type { LocalProjectSearchQueryChangeHandler } from "../../local/Projects/LocalProjectsList/LocalProjectsList";
import type { LocalProjectSearchQuery } from "../../local/Projects/LocalProjectsList/localProjectSearchQuery";
import type { StatewideProjectSearchQueryChangeHandler } from "../../statewide/Projects/StatewideProjectsList/StatewideProjectsList";
import {
  defaultStatewideProjectSearchQuery,
  type StatewideProjectSearchQuery,
} from "../../statewide/Projects/StatewideProjectsList/statewideProjectSearchQuery";
import type { ProjectSearchQueryChangeHandler } from "./ProjectsList";
import ProjectsStatusToggle from "./ProjectsStatusToggle";
import type { ProjectSearchQuery } from "./projectSearchQuery";

type StatewideProjectsProps = {
  isStatewideProjects: true;
  onSearchQueryChange: StatewideProjectSearchQueryChangeHandler;
  searchQuery: StatewideProjectSearchQuery;
};

type NonStatewideProjectsProps = {
  isStatewideProjects?: false;
  onSearchQueryChange: ProjectSearchQueryChangeHandler;
  searchQuery: ProjectSearchQuery;
};

type LocalProjectsProps = {
  isLocalProjects: true;
  onSearchQueryChange: LocalProjectSearchQueryChangeHandler;
  searchQuery: LocalProjectSearchQuery;
  typeFilters: ServiceProjectTypeAttribute[];
};

type NonLocalProjectsProps = {
  filterOptions: readonly AppFilterOption[];
  isLocalProjects?: false;
  onSearchQueryChange: ProjectSearchQueryChangeHandler;
  searchQuery: ProjectSearchQuery;
};

type ProjectsChipListProps = MergedUnion<
  StatewideProjectsProps | NonStatewideProjectsProps
> &
  MergedUnion<LocalProjectsProps | NonLocalProjectsProps>;

const ProjectsChipList = ({
  filterOptions,
  isLocalProjects,
  isStatewideProjects,
  onSearchQueryChange,
  searchQuery,
  typeFilters,
}: ProjectsChipListProps) => {
  return (
    <>
      {isStatewideProjects && (
        <ChipGroup>
          <ProjectsStatusToggle
            onStatusChange={(value) => {
              onSearchQueryChange({
                ...searchQuery,
                archived: value ?? defaultStatewideProjectSearchQuery.archived,
                page: 1,
              });
            }}
            status={searchQuery.archived}
          />
        </ChipGroup>
      )}
      {isLocalProjects && (
        <ChipGroup>
          <CheckboxGroupDropdownChip
            items={LocalProjectTypeItems}
            itemType="type"
            label="Type"
            onChange={(value) => {
              onSearchQueryChange({
                ...searchQuery,
                type: value,
                page: 1,
              });
            }}
            value={typeFilters}
            data-testid="project-type-filter"
          />
        </ChipGroup>
      )}
      <ChipGroup>
        <CheckboxGroupDropdownChip
          items={statusItems}
          itemType="status"
          label="Project status"
          onChange={(value) => {
            onSearchQueryChange({
              ...searchQuery,
              status: value,
              page: 1,
            });
          }}
          value={searchQuery.status}
          data-testid="project-status-filter"
        />
      </ChipGroup>
      {!isLocalProjects && (
        <ChipGroup>
          <CheckboxGroupDropdownChip
            items={bfrmpScenarioItems}
            itemType="scenario"
            label="Scenario"
            onChange={(value) => {
              onSearchQueryChange({
                ...searchQuery,
                stage: value,
                page: 1,
              });
            }}
            value={searchQuery.stage}
            data-testid="project-scenario-filter"
          />
        </ChipGroup>
      )}
      {!isLocalProjects && (
        <ChipGroup>
          <CheckboxGroupDropdownChip
            items={
              filterOptions.find(
                (filterOption) => filterOption.name === "owner",
              )?.options ?? []
            }
            itemType="owner"
            label="Owner"
            onChange={(value) => {
              onSearchQueryChange({
                ...searchQuery,
                owner: value,
                page: 1,
              });
            }}
            value={searchQuery.owner}
            data-testid="project-owner-filter"
          />
        </ChipGroup>
      )}
    </>
  );
};

export default ProjectsChipList;
