import { isEquivalentSet } from "@kablamo/kerosene";
import defaults from "lodash/defaults";
import type { ParsedUrlQuery, ParsedUrlQueryInput } from "querystring";
import {
  GetProjectsSortBy,
  ServiceProjectTypeAttribute,
  type GetProjectsParams,
} from "../../../../.rest-hooks/types";
import {
  type PageQuery,
  type ParsedUrlQueryValue,
  getStringFromQuery,
  pluckPageQueryFromQuery,
  getStringArrayFromQuery,
  getParamsFromPageQuery,
  getQueryFromPageQuery,
  getBooleanFromQuery,
} from "../../../../utils/pageQuery/pageQuery";

const GetProjectsSortByValues = Object.values(GetProjectsSortBy);

export interface StatewideProjectSearchQuery extends Required<PageQuery> {
  archived: boolean;
  owner: string[];
  q: string;
  sortBy: GetProjectsSortBy;
  stage: string[];
  status: string[];
  type: ServiceProjectTypeAttribute[];
}

export const defaultStatewideProjectSearchQuery: StatewideProjectSearchQuery = {
  archived: false,
  owner: [],
  page: 1,
  perPage: 10,
  q: "",
  sortBy: GetProjectsSortBy["-lastActivityDateTime"],
  stage: [],
  status: [],
  type: [
    ServiceProjectTypeAttribute["annual-works-plan-evaluation"],
    ServiceProjectTypeAttribute["annual-works-plan-forecast"],
    ServiceProjectTypeAttribute["statewide-maxfuels"],
    ServiceProjectTypeAttribute["statewide-snapshot"],
  ],
} as const;

export const isStatewideProjectsSortByOrder = (
  value: string,
): value is GetProjectsSortBy => {
  return GetProjectsSortByValues.includes(value as GetProjectsSortBy);
};

const getStatewideProjectsSortByOrderFromQuery = (
  value: ParsedUrlQueryValue,
): GetProjectsSortBy | undefined => {
  const sortByOrder = getStringFromQuery(value);
  if (!sortByOrder) {
    return undefined;
  }

  if (isStatewideProjectsSortByOrder(sortByOrder)) {
    return sortByOrder;
  }

  return undefined;
};

export const pluckStatewideProjectSearchQueryFromQuery = (
  query: ParsedUrlQuery,
): StatewideProjectSearchQuery => {
  const pageQuery = pluckPageQueryFromQuery(query);

  return defaults<
    Record<string, never>,
    PageQuery,
    Partial<StatewideProjectSearchQuery>,
    StatewideProjectSearchQuery
  >(
    {},
    pageQuery,
    {
      archived: getBooleanFromQuery(query.archived),
      owner: getStringArrayFromQuery(query.owner),
      q: getStringFromQuery(query.q),
      sortBy: getStatewideProjectsSortByOrderFromQuery(query.sortBy),
      stage: getStringArrayFromQuery(query.stage),
      status: getStringArrayFromQuery(query.status),
      type: getStringArrayFromQuery(
        query.type,
      ) as ServiceProjectTypeAttribute[],
    },
    defaultStatewideProjectSearchQuery,
  );
};

export const getParamsFromStatewideProjectSearchQuery = ({
  archived,
  owner,
  page,
  perPage,
  q,
  sortBy,
  stage,
  status,
  type,
}: StatewideProjectSearchQuery): GetProjectsParams => {
  const pageParams = getParamsFromPageQuery({ page, perPage });

  return {
    sortBy,
    ...pageParams,
    ...(archived && { archived }),
    owner,
    ...(q && { q }),
    stage,
    status,
    type,
  };
};

export const getQueryFromStatewideProjectSearchQuery = ({
  archived,
  owner,
  page,
  perPage,
  q,
  sortBy,
  stage,
  status,
  type,
}: StatewideProjectSearchQuery): ParsedUrlQueryInput => {
  return {
    ...getQueryFromPageQuery({ page, perPage }),
    ...(archived && { archived }),
    ...(owner.length && { owner }),
    ...(q && { q }),
    ...(sortBy &&
      sortBy !== defaultStatewideProjectSearchQuery.sortBy && { sortBy }),
    ...(stage.length && { stage }),
    ...(status.length && { status }),
    ...(type.length &&
      !isEquivalentSet(type, defaultStatewideProjectSearchQuery.type) && {
        type,
      }),
  };
};
